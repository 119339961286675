// var Object = function(options) {
    
//     var that = {};
        
//     var defaults = {};
    
//     var opt = $.extend({}, defaults, options);
    
//     that.init = function(){
//     };

//     return that;
    
// };

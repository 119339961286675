var PartnersSlider = function(screenHelper, options) {
    
    var that = {};
        
    var defaults = {
        el: '.PartnersSlider'
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
        if ( $('.PartnersSlider').length ) {
            
            that.slider = $(opt.el).lightSlider({
                item: 7,
                autoWidth: false,
                slideMove: 3,
                slideMargin: 10,
                
                addClass: '',
                mode: 'slide',
                useCSS: true,
                cssEasing: 'ease', //'cubic-bezier(0.25, 0, 0.25, 1)',
                easing: 'linear', //'for jquery animation',//
                
                speed: 2000, //ms'
                auto: true,
                loop: true,
                slideEndAnimation: true,
                pause: 4000,
                
                keyPress: false,
                controls: false, // prev next
                prevHtml: '',
                nextHtml: '',
                
                rtl: false,
                adaptiveHeight: false,
                
                vertical: false,
                verticalHeight: 500,
                vThumbWidth: 100,
                
                thumbItem: 5,
                pager: false,
                gallery: false, // pager turns into thumbnails
                galleryMargin: 5,
                thumbMargin: 5,
                currentPagerPosition: 'middle',
                
                enableTouch: true,
                enableDrag: true,
                freeMove: true,
                swipeThreshold: 40,
                
                responsive: [
                    {
                        breakpoint: screenHelper.opt.SIZE_MD,
                        settings: {
                            item: 3,
                            slideMove: 1,
                            slideMargin: 6,
                          }
                    },
                ],
                
                onBeforeStart: function ($el) {},
                onSliderLoad: function ($el) {},
                onBeforeSlide: function ($el, scene) {},
                onAfterSlide: function ($el, scene) {},
                onBeforeNextSlide: function ($el, scene) {},
                onBeforePrevSlide: function ($el, scene) {}
            });

            // that.slider.goToSlide(3);
            // that.slider.goToPrevSlide();
            // that.slider.goToNextSlide();
            // that.slider.getCurrentSlideCount();
            // that.slider.refresh();
            // that.slider.play(); 
            // that.slider.pause();

        }
    };

    return that;
    
};

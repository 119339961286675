var MenuScaler = function(screenHelper, options) {
    
    var that = {};
        
    var defaults = {
        el: '.NavbarMain',
        NAVBAR_HEIGHT: 80,
        NAVBAR_LINKS_MIN_PADDING_LG: 10,
        NAVBAR_LINKS_MIN_PADDING_MD: 7,
        NAVBAR_LINKS_MIN_PADDING_SM: 5,
        NAVBAR_LINKS_MIN_FONT_SIZE: 10,
        NAVBAR_LAST_CHILD_PADDING_RIGHT_ZERO: false
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
        // -- store initial font-size
        $(opt.el + ' .navbar-nav').data(
            'level-1-font-size', 
            parseInt( $(opt.el + ' .navbar-nav > li > a').css('font-size'), 10 )
        );
        
        // -- store initial padding-left
        $(opt.el + ' .navbar-nav').data(
            'level-1-padding-left', 
            parseInt( $(opt.el + ' .navbar-nav > li > a').css('padding-left'), 10 )
        );
        
        // -- store initial padding-right
        $(opt.el + ' .navbar-nav').data(
            'level-1-padding-right', 
            parseInt( $(opt.el + ' .navbar-nav > li > a').css('padding-right'), 10 )
        );
        
        $(opt.el + ' .navbar-nav').data('navbar-height', opt.NAVBAR_HEIGHT );
        
    };

    that.menuHideBeforeAutoscale = function() {
        
        $(opt.el + ' .navbar-nav').css('visibility', 'hidden');
    };

    that.menuShowAfterAutoscale = function() {
        
        $(opt.el + ' .navbar-nav').css('visibility', 'visible');
    };

    that.autoScale = function() {
        
        var menuDecrementSize = function() {
            
            app.clog( 'MENU-SCALER: MENU DECREMENET SIZE' );
            
            var curFs = parseInt( $(opt.el + ' .navbar-nav > li > a').css( 'font-size' ), 10 );
            var curPL = parseInt( $(opt.el + ' .navbar-nav > li > a').css( 'padding-left' ), 10 );
            var curPR = parseInt( $(opt.el + ' .navbar-nav > li > a').css( 'padding-right' ), 10 );
            
            // --- check if padding is over minimal
            if ( 
                ( screenHelper.isLG() && ( curPL > opt.NAVBAR_LINKS_MIN_PADDING_LG && curPR > opt.NAVBAR_LINKS_MIN_PADDING_LG ) ) || 
                ( screenHelper.isMD() && ( curPL > opt.NAVBAR_LINKS_MIN_PADDING_MD && curPR > opt.NAVBAR_LINKS_MIN_PADDING_MD ) ) || 
                ( screenHelper.isSM() && ( curPL > opt.NAVBAR_LINKS_MIN_PADDING_SM && curPR > opt.NAVBAR_LINKS_MIN_PADDING_SM ) )           
            ) {         
                $(opt.el + ' .navbar-nav > li > a').css( 'padding-left', curPL - 1 + 'px' );
                $(opt.el + ' .navbar-nav > li > a').css( 'padding-right', curPR - 1 + 'px' );
                return;
            }
            
            // if padding is to smaal decrement font-size
            $(opt.el + ' .navbar-nav > li > a').css( 'font-size', curFs - 1 + 'px' );
            return;
                
        };
        
        var menuResetFonts = function() {
        
            app.clog( 'MENU-SCALER: MENU RESET FONTS' );
            $(opt.el + ' .navbar-nav > li > a').css( 'font-size', $(opt.el + ' .navbar-nav').data('level-1-font-size') + 'px' );
            $(opt.el + ' .navbar-nav > li > a').css( 'padding-left', $(opt.el + ' .navbar-nav').data('level-1-padding-left') + 'px' );
            $(opt.el + ' .navbar-nav > li > a').css( 'padding-right', $(opt.el + ' .navbar-nav').data('level-1-padding-right') + 'px' );
            
        };
        
        menuResetFonts();
        console.log( screenHelper );
        if ( screenHelper.isFloated() ) {
            app.clog( 'MENU-SCALER: IS FLOATED' );
            that.menuShowAfterAutoscale();
            return false;
        }
        
            
        (function menuCheckHeight(){
            
            // --- if navbar to high and font-size over minimal decremet menu size
            if ( 
                $(opt.el + '').height() > $(opt.el + ' .navbar-nav').data('navbar-height') &&
                parseInt( $(opt.el + ' .navbar-nav > li > a').css( 'font-size'), 10) > opt.NAVBAR_LINKS_MIN_FONT_SIZE
            ) {
                app.clog( 'MENU-SCALER: MENU CHECK HEIGHT' );
                menuDecrementSize();
                setTimeout( menuCheckHeight, 5 );
            } else {
                that.menuShowAfterAutoscale();
            }
            
            if (opt.NAVBAR_LAST_CHILD_PADDING_RIGHT_ZERO) {
                $(opt.el + ' .navbar-nav > li:last > a').css('padding-right', 0);
            }
            
        })();
        
    };

    return that;
    
};

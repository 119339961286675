var ScreenHelper = function(options){
    
    var that = {};
    
    var defaults = {
        GRID_GUTTER: 30,
        GRID_FLOAT_BREAKPOINT: 768,
        SIZE_XS: 486,
        SIZE_SM: 768,
        SIZE_MD: 992,
        SIZE_LG: 1230
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.opt = opt;
    
    that.isTouchDevice = function() {
        var bool;
        if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            bool = true;
        } else {
            bool = false;
        }
        return bool;
    };
    
    that.isIpad = function() { 
        return navigator.userAgent.match(/iPad/i); 
    };
    
    that.isIphone = function() { 
        return navigator.userAgent.match(/iPhone/i); 
    };
    
    that.documentWidth = function() { 
        return $(document).width(); 
    };
    
    that.documentHeight = function() { 
        return $(document).height(); 
    };
    
    that.windowWidth = function() { 
        return $(window).width(); 
    };
    
    that.windowHeight = function() { 
        return $(window).height(); 
    };
    
    that.isFloated = function() {
        console.log( 'ISFLOATED' );
        console.log( that.windowWidth() );
        console.log( opt.GRID_FLOAT_BREAKPOINT );
        console.log( that.windowWidth() < opt.GRID_FLOAT_BREAKPOINT );
        return that.windowWidth() < opt.GRID_FLOAT_BREAKPOINT;
    };
    
    that.isXXS = function() {
        if (that.windowWidth() < opt.SIZE_XS) return true;
        return false;
    };
    
    that.isXS = function() {
        if (that.windowWidth() >= opt.SIZE_XS && that.windowWidth() < opt.SIZE_SM) return true;
        return false;
    };
    
    that.isSM = function() {
        if (that.windowWidth() >= opt.SIZE_SM && that.windowWidth() < opt.SIZE_MD) return true;
        return false;
    };
    
    that.isMD = function() {
        if (that.windowWidth() >= opt.SIZE_MD && that.windowWidth() < opt.SIZE_LG) return true;
        return false;
    };
    
    that.isLG = function() {
        if (that.windowWidth() >= opt.SIZE_LG) return true;
        return false;
    };
    
    that.isXSmin  = function() {
        if (that.windowWidth() >= opt.SIZE_XS) return true;
        return false;
    };
    
    that.isSMmin = function() {
        if (that.windowWidth() >= opt.SIZE_SM) return true;
        return false;
    };
    
    that.isMDmin = function() {
        if (that.windowWidth() >= opt.SIZE_MD) return true;
        return false;
    };

    that.isLGmin = function() {
        if (that.windowWidth() >= opt.SIZE_LG) return true;
        return false;
    };

    that.isXXSmax = function() {
        if (that.windowWidth() < opt.SIZE_XS) return true;
        return false;
    };
    
    that.isXSmax = function() {
        if (that.windowWidth() < opt.SIZE_SM) return true;
        return false;
    };
    
    that.isSMmax = function() {
        if (that.windowWidth() < opt.SIZE_MD) return true;
        return false;
    };
    
    that.isMDmax = function() {
        if (that.windowWidth() < opt.SIZE_LG) return true;
        return false;
    };
    
    return that;   
};

var ExternalLinks = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        $('a[href^="http"]:not([href*="' + document.domain + '"])').each(function () {
            $(this).attr("target", "_blank");
        });
    };

    return that;
    
};

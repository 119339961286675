var ImgLeadingSlash = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        $('img').each(function(index,Element){
            if ( $(Element).attr('src').substring(0,1) !== '/' && $(Element).attr('src').substring(0,4) !== 'http' ) {
                $(Element).attr('src','/' + $(Element).attr('src'));
            }
        });
    };

    return that;
    
};

var ModalPopup = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        if ($(window).height() >= 320) {
            $(window).resize(that.adjustModalMaxHeightAndPosition).trigger("resize");
        }
        
        $('.popup-modal').on('shown.bs.modal', function() {
            setTimeout(
                function() {
                    that.adjustModalMaxHeightAndPosition();
                    $('.popup-modal').css('opacity', '1');
                },
                2000
            );
        });
    };

    that.adjustModalMaxHeightAndPosition = function() {
        $('.modal').each(function() {
            if ($(this).hasClass('in') === false) {
                $(this).show();
            }
            var contentHeight = $(window).height() - 60;
            var headerHeight = $(this).find('.modal-header').outerHeight() || 2;
            var footerHeight = $(this).find('.modal-footer').outerHeight() || 2;

            $(this).find('.modal-content').css({
                'max-height': function() {
                    return contentHeight;
                }
            });

            $(this).find('.modal-body').css({
                'max-height': function() {
                    return contentHeight - (headerHeight + footerHeight);
                }
            });

            $(this).find('.modal-dialog').addClass('modal-dialog-center').css({
                'margin-top': function() {
                    return -($(this).outerHeight() / 2);
                },
                'margin-left': function() {
                    return -($(this).outerWidth() / 2);
                }
            });
            if ($(this).hasClass('in') === false) {
                $(this).hide();
            }
        });
    };

    return that;
    
};

// app.popupOnDemand = function() {
    
//     // $('body').on('click','a[href*="popups"]',function(event){
        
//     //  event.preventDefault();
        
//     //  var baseHref = $('head base').attr('href');     
//     //  var $a = $(this);
//     //  var $aHref = $a.attr('href');
        
//     //  $aHref = baseHref + $aHref;
        
//     //  $.ajax({
//     //      url: $aHref,
//     //      type: 'GET',
//     //      dataType: 'html'
//     //  })
//     //  .done(function(data) {
        
//     //      $('#modalPopupOnDemand').find('.modal-body').html(data);
//     //      $('#modalPopupOnDemand').modal('show');
            
//     //  })
//     //  .fail(function() {
//     //      console.log("error");
//     //  })
//     //  .always(function() {
//     //      console.log("complete");
//     //  });
        
        
        
//     // });

// };
var GallerySlider = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
        if ( $('.gallery-single-slider').length ) {
            
            $('.gallery-single-slider').bxSlider({
                minSlides: 1,
                maxSlides: 4,
                slideWidth: 263,
                moveSlides: 1,
                slideMargin: 10,
                pager: false,
                slideSelector: '.gallery-single-photo-wrp',
                responsive: true,
                infiniteLoop: false
                // nextText: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
                // prevText: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>'
            });

        }
        
        
        // if ( $('.gallery-1-slider').length ) {
            
        //     $('.gallery-1-slider').bxSlider({
        //         minSlides: 1,
        //         maxSlides: 4,
        //         slideWidth: 263,
        //         moveSlides: 1,
        //         slideMargin: 10,
        //         pager: false,
        //         slideSelector: '.gallery-1-photo-wrp',
        //         responsive: true,
        //         infiniteLoop: false
        //         // nextText: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
        //         // prevText: '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>'
        //     });

        // }
        
    };

    return that;
    
};

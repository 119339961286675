var TableResponsive = function(options) {
    
    var that = {};
        
    var defaults = {
        el: 'table.responsive',
        hiddenXsClass: 'hidden-xs'
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
    };
    
    that.removeEmptyCellsInXS = function() {
        
        $(opt.el).find('td').filter(function(){ return $.trim($(this).html()) === '&nbsp;'; }).addClass(opt.hiddenXsClass);
        
    };

    return that;
    
};

$(function() {

	// Month changing
	$('#Calendar').on('click', '.calendar .prev a, .calendar .next a', function(event) {
		event.preventDefault();
		var cal = $(this).parentsUntil('.calendar').parent();
		var href = $(this).attr('href');
		console.log( cal );
		// alert(href);
		cal.css('opacity', 0.7);

		var width = cal.css('width');
		var height = cal.css('height');
		cal.parent().find('.cover').css({'width': width, 'height': height, 'margin-top': '-' + height}).show();

		$.get(href, function(data) {
				cal.parent().find('.cover').hide();
				cal.replaceWith(data);
			}
		);
		return false;
	});

	// Display events
	$('#Calendar').on('click', '.calendar .isevent', function(event) {
		
		if ($(event.target).is('a')) {
			// if link inside cell is clicked
			return true;
		} else {
			// if cell is clicked
			event.preventDefault();		
			$('#' + this.id + ' .event').fadeIn();
			return false;
		}
	});
	
	$('#Calendar').on('mouseleave', '.calendar .isevent', function() {
		$('#' + this.id + ' .event').fadeOut();
	});
	
});
var Colorbox = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){

        $("a.colorbox-iframe").colorbox({
            iframe: true,
            innerWidth: "100%",
            innerHeight: "100%",
            //width: "768px",
            maxWidth: "95%",
            maxHeight: "95%",
            className: 'iframe',
            fixed: true
        });

        $("a.lightbox,a.fancybox,a.colorbox,a[href$='.jpg'],a[href$='.png'],a[href$='.gif']").colorbox({
            scalePhotos: true,
            maxWidth: "90%",
            maxHeight: "90%",
            fixed: true//,
            //opacity: 0.75,
            //overlayClose: false,
            //next: '<i class="fa fa-arrow-right">',
            //previous: '<i class="fa fa-arrow-left">',
            //close: "",
            //current: function(){              
            //},
            //onComplete: function(){               
            //}
        });

        $('a[href*="popups/video"]').colorbox({
            iframe: true,
            //innerWidth: "853",
            //innerHeight: "480",
            width: "877px",
            height: "526px",
            maxWidth: "95%",
            maxHeight: "95%",
            className: 'iframe',
            fixed: true
        });

        var ts;
        $('body').on('touchstart', '#cboxOverlay, #colorbox', function (event){
            ts = event.originalEvent.touches[0].clientX;
        });
        $('body').on('touchend', '#cboxOverlay, #colorbox', function (event){
            var te = event.originalEvent.changedTouches[0].clientX;
            if(ts > te+5){
                $.colorbox.next();
            } else if (ts < te-5){
                $.colorbox.prev();
            }
        });
        $('body').on('touchmove', '#cboxOverlay, #colorbox', function (event){
            event.preventDefault();
        });

        $('.colorbox-html').each(function(){
            var $target = null;

            if ($(this).attr('data-target')){
                $target = $($(this).attr('data-target'));
            }

            if ($target){
                $(this).colorbox({
                    width: "640px",
                    maxWidth: "95%",
                    maxHeight: "95%",
                    html: $target.html()
                });
            }
        });
    };
    
    return that;
    
};
var Resizer = function (options) {
    
    var that = {};
    
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    var resizeEvents = [];
    
    that.addResizeEvent = function(event){
        resizeEvents.push(event);
    };
    
    that.runResizeEvents = function(){
        $.each(resizeEvents, function(key, val){
            val();
        });
    };
    
    that.setBodyWidth = function() {
        $('body').data('body-width', $('body').width());
    };
    
    that.checkIfResize = function() {
        return $('body').data('body-width') !== $('body').width();
    };
    
    return that;
    
    //,
    /*updateScale: function(){
        var $viewport = $('#viewport');

        if (appTest.isIphone()){
            if (typeof window.orientation != 'undefined' && Math.abs(window.orientation) === 90){
                $viewport.attr("content", "width=480");
            } else {
                $viewport.attr("content", "width=320");
            }
        }
    },*/
    /*fixHistoryBack: function(){
        if (appTest.isIphone() || appTest.isIpad()){
            $(window).on("pagecontainershow", function(event) {
                if (event.originalEvent.persisted){
                    $('html').remove();
                    window.location.reload();
                }
            });
        }
    }*/
};
$.fn.equalizeHeights = function(autoHeight) {
    
    var two = $(this).map(function(i, e) {
        var displayBuffer = $(e).css('display');
        var positionBuffer = $(e).css('position');
        $(e).css('display','block');
        $(e).css('position','static');
        $(e).css('height','auto');
        var autoHeight = $(e).outerHeight();
        $(e).css('display',displayBuffer);
        $(e).css('position',positionBuffer);
        return autoHeight;
    });
    
    if (autoHeight) {
        return false;
    }
    else {
        return this.outerHeight(Math.max.apply(this,two.get()));
    }
};

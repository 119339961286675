var ScrollTo = function(options) {
    
    var that = {};
        
    var defaults = {
        className: '.scroll-to'
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){

        $(opt.className).on('click', function(event){
            event.preventDefault();
            var $target = $($(this).attr('href'));

            if ($target.length > 0){
                $('html, body').animate({
                    scrollTop: $target.offset().top
                }, 250);
            }
        });
    };
    
    return that;
    
};

var AjaxLinks = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
        $('body').on('click', '.ajax-link', function(event){
            
            event.preventDefault();
            
            var baseHref = $('head base').attr('href');
            
            var $a = $(this);
            var $aHref = $a.attr('href');
            var $aResult = $a.data('result');
            var $aScheme = $a.data('scheme');
            var $aType = $a.data('type');
            var $aDataType = $a.data('datatype');
            
            var ajaxResult;
            
            if ( $aScheme != 'full' ) {
                $aHref = baseHref + $aHref;
            }
            
            $aType = $aType || 'GET';
            $aDataType = $aDataType || 'json';
            
            ajaxResult = $aResult || 'ajax-result';
            ajaxResult = '.' + ajaxResult;
            
            $.ajax({
                url: $aHref,
                type: $aType,
                dataType: $aDataType/*,
                data: {param1: 'value1'},*/
            })
            .done(function(data) {
                $(ajaxResult).html(data);
            })
            .fail(function() {
                console.log("error");
            })
            .always(function() {
                console.log("complete");
            });
            
        });

    };

    return that;
    
};

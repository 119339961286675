var ScrollTop = function(options) {
    
    var that = {};
        
    var defaults = {
        el: '.scrollTop'
    };
    
    var opt = $.extend({}, defaults, options);
    
    that.addScrollTopAnimation = function() {

        var $scrolltop_link = $(opt.el);

        $scrolltop_link.data('hidden', 1).hide();

        $scrolltop_link.on( 'click' ,  function ( event ) {
            event.preventDefault();
            $('html, body').animate( {
                scrollTop: 0
            }, 700 );
        });

        var scroll_event_fired = false;

        $(window).on('scroll', function() {
            scroll_event_fired = true;
        });

        var checkScrollTopVisibility = function() {
            if( scroll_event_fired ) {
                scroll_event_fired = false;
                var is_hidden =  $scrolltop_link.data('hidden');

                if ($(this).scrollTop() > $(this).height() / 2){
                    if(is_hidden) {
                        $scrolltop_link.slideDown(600).data('hidden', 0);
                    }
                } else {
                    if(!is_hidden){
                        $scrolltop_link.slideUp(600).data('hidden', 1);
                    }
                }
            }           
            setTimeout(function() {
                checkScrollTopVisibility();
            }, 1000);
        };
        checkScrollTopVisibility();

    };

    return that;
    
};

var BootstrapSelect = function(options) {
    
    var that = {};
        
    var defaults = {};
    
    var opt = $.extend({}, defaults, options);
    
    that.init = function(){
        
        if ( ! $('.selectpicker').length ) return;
        
        $('.selectpicker').selectpicker();
        
        $('.selectpicker').each(function(index, Element){
            
            var $bs = $(Element);
            
            if ( $bs.data('empty-text') ) {
            
                $bs.siblings('.bootstrap-select').find('.dropdown-menu > ul > li:first > a > .text').text( $bs.data('empty-text') );
                
            }
            
        });
        
    };

    return that;
    
};
